import React, { useRef, useEffect, useState } from "react";

import RSModalSpinner from "../components/etc/RSModalSpinner";
import { blogType } from "./BlogAdmin";

import SidebarHome from "../components/content/Front/SidebarHome";

import axios from "axios";

import { useParams } from "react-router-dom";
import ListBlog from "../components/content/Front/ListBlog";

interface typeRef {
  setStatusModal(value: boolean): void;
}

export default function SearchBlog() {
  const [blog, setBlog] = useState<blogType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  let { judul } = useParams();

  useEffect(() => {
    async function getPostInKategori() {
      childRef.current?.setStatusModal(true);
      setLoading(true);

      await axios
        .get(`${window.config.api}/getsearchblog/${judul}`)
        .then((res) => {
          console.log(res);
          if (res.data.Error == 0) {
            setBlog(res.data.blog || []);
          }else{
            setBlog([]);
          }
          window.scrollTo({top: 0, behavior: 'smooth'});
          childRef.current?.setStatusModal(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          childRef.current?.setStatusModal(false);
          setLoading(false);
        });
    }

    getPostInKategori();
  }, [judul]);

  const childRef = useRef<typeRef>(null);
  return (
    <>
      <div className="div_after_search">
        <div className="div_judul_after_search">
          Search Results For : {judul}
        </div>
        <div className="divider"></div>
      </div>

      <div className="content_home">
        <ListBlog loading={loading} blog={blog} isLatestBlog={false} />
        <SidebarHome />
      </div>
      <RSModalSpinner ref={childRef} />
    </>
  );
}
