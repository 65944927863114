import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalNotif from "../../etc/ModalNotif";

export default function SidebarHome() {
  const [judul, setJudul] = useState<string>("");
  // to={`/blog/${data.url_slug}`}

  const navigate = useNavigate();

  function CariBlog(e: React.SyntheticEvent) {
    console.log("CariBlog");
    e.preventDefault();

    if (judul.replace(/\s/g, "") === "") {
      ModalNotif("error", "Title Cannot Empty!", "");
    } else {
      navigate(`/blog/search/${judul}`);
    }
  }

  return (
    <div className="sidebar_home">
      {/* <div className="div_search_sidebar">
        <div className="txt_search_post">Search Post</div>
        <form onSubmit={CariBlog}>
          <div className="div_content_search">
            <input
              required
              onChange={(e) => setJudul(e.target.value)}
              className="input_search_post"
              placeholder="Title..."
              type="text"
            />
            <button type="button" className="btn btn-primary btn_search_post">Search</button>
          </div>
        </form>
      </div> */}
      <div className="div_sidebar_ads">
        <center>Advertisement</center>
      </div>
    </div>
  );
}
