import React, { useState, useEffect, useRef } from "react";

import { Helmet } from "react-helmet-async";
import RSModalSpinner from "../components/etc/RSModalSpinner";

import axios from "axios";

import "../assets/style/toggleSwitch.scss";
import ModalNotif from "../components/etc/ModalNotif";

interface typeRef {
  setStatusModal(value: boolean): void;
}

export default function ConfigAdmin() {
  const [code, setCode] = useState<string>("");
  const [SCode, setSCode] = useState<number>(0);
  const [timerAds, setTimerAds] = useState<number>(0);
  const [pages, setPages] = useState<number>(0);

  function getConfigWebsite2() {
    axios
      .get(`${window.config.api}/getconfigwebsite`)
      .then((res) => {
        console.log(res);
        if (res.data.Error == 0) {
          setTimerAds(res.data.config[0]["timerAds"]);
          setPages(res.data.config[0]["pages"]);
          setSCode(res.data.config[0]["showCode"]);
          setCode(res.data.config[0]["code"]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getConfigWebsite2();
  }, []);

  function setConfigWebsite() {
    childRef.current?.setStatusModal(true);

    var formData = new FormData();
    formData.append("code", code);
    formData.append("SCode", SCode.toString());
    formData.append("timerAds", timerAds.toString());
    formData.append("pages", pages.toString());

    axios
      .post(`${window.config.api}/setconfigwebsite`, formData)
      .then((res) => {
        console.log(res);
        childRef.current?.setStatusModal(false);
        if (res.data.Error == 0) {
          getConfigWebsite2();
          ModalNotif("success", res.data.Message, "");
        } else {
          ModalNotif("error", res.data.Message, "");
        }
      })
      .catch((err) => {
        console.log(err);
        ModalNotif("error", "Simpan Gagal", "Terjadi kesalahan pada sistem!");
        childRef.current?.setStatusModal(false);
      });
  }

  const childRef = useRef<typeRef>(null);

  return (
    <>
      <Helmet>
        <title>Config | {window.config.appname}</title>
      </Helmet>
      <RSModalSpinner ref={childRef} />
      <div className="div_title_blog_page">
        <div className="div_title_blog">CONFIG</div>
        <button className="btn_admin" onClick={() => setConfigWebsite()}>
          Simpan
        </button>
      </div>
      <div className="hr" />
      <div className="content_blog_utama">
        <div className="form_config">
          <div className="div_form_config dfc_kiri">
            <div className="div_fg">
              <div className="label_input_blog">Kode</div>
              <input type="text" value={code} onChange={(e)=> setCode(e.target.value)} className="input_text_blog" />
            </div>
            <div className="div_fg">
              <div className="label_input_blog">Aktifkan Kode</div>
              <input
                defaultChecked={SCode === 1}
                checked={SCode === 1}
                onChange={() => setSCode((s) => (s == 1 ? 0 : 1))}
                type="checkbox"
                id="switch"
              />
              <label htmlFor="switch">Toggle</label>
            </div>
          </div>
          <div className="div_form_config">
            <div className="div_fg">
              <div className="label_input_blog">Timer tiap halaman</div>
              <input
                type="number"
                min={5}
                value={timerAds}
                onChange={(e)=> setTimerAds(parseInt(e.target.value))}
                className="input_text_blog"
              />
            </div>
            <div className="div_fg">
              <div className="label_input_blog">Jumlah halaman</div>
              <input
                type="number"
                value={pages}
                onChange={(e)=> setPages(parseInt(e.target.value))}
                min={1}
                className="input_text_blog"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
