import React, { lazy } from "react";



import { Route, Routes, useParams } from "react-router-dom";


// const FrontLayout = lazy(() => import('./FrontLayout'));
// const Home = lazy(() => import('../pages/Home'));
// const DetailBlog = lazy(() => import('../pages/DetailBlog'));
// const Kategori = lazy(() => import('../pages/Kategori'));
// const SearchBlog = lazy(() => import('../pages/SearchBlog'));

import FrontLayout from "./FrontLayout";
import Home from "../pages/Home";
import DetailBlog from "../pages/DetailBlog";
import Kategori from "../pages/Kategori";
import SearchBlog from "../pages/SearchBlog";

// import HeaderFront from "../components/header/HeaderFront";
// import FooterFront from "../components/footer/FooterFront";
// import MenuFront from "../components/menu/MenuFront";

export default function Blog(props : any) {
  return (
    <>
      {/* <HeaderFront />
      <MenuFront />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:url_slug" element={<DetailBlog />} />
        <Route path="/category/:nama/:id" element={<Kategori />} />
      </Routes> */}
      {/* <FooterFront /> */}
      <FrontLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/page/:page?" element={<Home />} />
          
          {/* <Route path="/page/:page" element={(props) => <Home {...props} key={Date.now()}/>} /> */}
          <Route path="/:url_slug" element={<DetailBlog />} />
          <Route path="/category/:nama/:id" element={<Kategori />} />
          <Route path="/search/:judul" element={<SearchBlog />} />
        </Routes>
      </FrontLayout>
    </>
  );
}

function About() {
  let { id } = useParams();
  console.log(id);
  return <h2>About</h2>;
}
